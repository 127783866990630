<template>
  <div class="loadingio-spinner-dual-ring-v9spro26znb">
    <div class="ldio-p2v5oy66rye">
      <div></div><div><div></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation'
}
</script>

<style lang="less">
@keyframes ldio-p2v5oy66rye {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-p2v5oy66rye div { box-sizing: border-box!important }
.ldio-p2v5oy66rye > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #f5c037 transparent #f5c037 transparent;
  animation: ldio-p2v5oy66rye 1s linear infinite;
}
.ldio-p2v5oy66rye > div:nth-child(2) { border-color: transparent }
.ldio-p2v5oy66rye > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-p2v5oy66rye > div:nth-child(2) div:before, .ldio-p2v5oy66rye > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #f5c037;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #f5c037;
}
.ldio-p2v5oy66rye > div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #f5c037;
}
.loadingio-spinner-dual-ring-v9spro26znb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-p2v5oy66rye {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-p2v5oy66rye div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
